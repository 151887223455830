<template>
  <div class="subpage-container">
    <div class="slick-container fullscreen">
      <VueSlickCarousel
        ref="slick"
        :infinite="false"
        :arrows="false"
        @afterChange="onAfterChange"
      >
        <div class="slide-item">
          <div class="slide-container--text">
            <div class="text-container scene-title">
              <h1>第{{ sceneNo[$route.params.id - 1] }}幕</h1>
            </div>
          </div>
        </div>
        <div
          class="slide-item"
          v-for="i in totalImage[$route.params.id - 1]"
          :key="`image-${i - 1}`"
        >
          <img
            :src="
              require(`@/assets/images/gallery3/scene${$route.params.id}/scene${
                $route.params.id
              }-${i - 1}.jpg`)
            "
            class="slide-image"
          />
        </div>
      </VueSlickCarousel>
      <div
        :class="{
          'btn-controls': true,
        }"
      >
        <div
          v-if="showArrows && currentSlide != 0"
          :class="{ 'btn-prev': true }"
          @click="prev"
        >
          <inline-svg :src="require(`@/assets/images/arrow-lg.svg`)" />
        </div>
        <div
          v-if="showArrows && !lastSectionSlide"
          :class="{ 'btn-next': true }"
          @click="next"
        >
          <inline-svg :src="require(`@/assets/images/arrow-lg.svg`)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
  data() {
    return {
      currentSlide: 0,
      totalImage: [6, 23, 8, 12, 16],
      sceneNo: ["一", "二", "三", "四", "五"],
    };
  },
  components: { VueSlickCarousel },
  mounted() {
    this.$store.commit(
      "updateGallery3CurrentSection",
      this.$route.params.id - 1
    );

    if (this.$route.params.id == 1) {
      this.$nextTick(() => {
        ScrollTrigger.create({
          trigger: ".subpage-container",
          start: "top center",
          end: "bottom top-=500",
          onToggle: (self) => {
            if (self.isActive == undefined) {
              this.$store.commit("updateShowArrows", true);
            }
            if (self.isActive) {
              this.$store.commit("updateShowArrows", true);
            } else {
              if (this.$route.params.id == 1) {
                this.$store.commit("updateShowArrows", false);
              }
            }
          },
        });
      });
    }
  },
  methods: {
    onAfterChange(slideIndex) {
      this.currentSlide = slideIndex;
    },
    prev() {
      this.$refs.slick.prev();
    },
    next() {
      if (
        this.currentSlide == this.totalImage[this.currentSection] &&
        this.$route.params.id < 5
      ) {
        this.$router.push({
          name: "Scenes",
          params: { id: this.currentSection + 2 },
        });
        this.$store.commit(
          "updateGallery3CurrentSection",
          this.currentSection + 1
        );
        this.$store.commit("updateShowArrows", true);
      } else if (
        this.currentSlide == this.totalImage[this.currentSection] &&
        this.$route.params.id == 5
      ) {
        this.$router.push({ name: "Museum" });
      } else {
        this.$refs.slick.next();
      }
    },
  },
  computed: {
    showArrows() {
      return this.$store.state.gallery3.showArrows;
    },
    currentSection() {
      return this.$store.state.gallery3.currentSection;
    },
    lastSectionSlide() {
      return this.currentSlide == this.totalImage[this.currentSection] &&
        this.$route.params.id == 5
        ? true
        : false;
    },
    currentScene() {
      return this.$route.params.id;
    },
  },
  watch: {
    currentSection() {
      this.currentSlide = 0;
      this.$refs.slick.goTo(0);
    },
    currentScene(val) {
      if (val != 1) {
        this.$store.commit("updateShowArrows", true);
      }
    },
  },
};
</script>

<style lang="scss">
.scene-title h1 {
  color: #fff;
  font-size: 3rem;
  letter-spacing: 0.5rem;
}
</style>
